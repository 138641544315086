import React from "react";
import Layout from "../components/layout";
import Restaurant from "../components/modules/TwoColSection";
import Seo from "../components/seo";

const RestaurantPage = () => {
  return (
    <Layout>
      <Seo title="Restaurante" />
      {/* <h1 className="title uppercase"> From Restaurant</h1> */}
      <Restaurant />
    </Layout>
  );
};

export default RestaurantPage;
