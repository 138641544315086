import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Restaurant = () => {
  return (
    <div className="grid lg:grid-cols-2 mx-auto place-items-center gap-4 pb-8 ">
      <div className="text-center paragraph px-2 lg:px-0">
        <h2 className="title leading-tight  pb-8 uppercase">
          Nuestro <br /> restaurante
        </h2>
        <p>
          En Cabañas Vero encuentras todo lo que necesitas. En el corazón del
          complejo está nuestro restaurante, atendido por sus dueños. Allí
          puedes degustar unos riquísimos platillos de la comida tradicional
          mexicana como camarones frescos empanizados, o a la diabla, tiritas de
          pescado, langostas, hamburguesas de camarón y de carne, burritos de
          camarón, pescado, carne y jamón, pescado huachinango entre otras
          delicias.{" "}
        </p>
        <br />
        <p>
          Nuestro restaurante es también el espacio ideal para trabajar desde la
          computadora mirando al mar y tomando un licuado fresco, ya que en el
          Restaurante contamos con buena conexión a internet.{" "}
        </p>
        <br />
        <p>Abrimos nuestra cocina a las 8.30 am.</p>
        <br />
        <p>Ven a probar nuestros platos!</p>
      </div>
      <div className="flex overflow-x-scroll snap-x lg:grid lg:grid-cols-2 gap-4 ml-auto pt-8">
        <div className="snap-center flex-shrink-0">
          <StaticImage
            width={268}
            src="../../../images/Restaurante/Mobile/Restaurante1.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            width={268}
            src="../../../images/Restaurante/Mobile/Restaurante2.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0 hidden lg:block">
          <StaticImage
            width={268}
            src="../../../images/Restaurante/Mobile/Restaurante3.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0 hidden lg:block">
          <StaticImage
            width={268}
            src="../../../images/Restaurante/Mobile/Restaurante4.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            width={268}
            src="../../../images/Restaurante/Mobile/Restaurante5.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            width={268}
            src="../../../images/Restaurante/Mobile/Restaurante6.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
      </div>
    </div>
  );
};

export default Restaurant;
